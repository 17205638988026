export const COLORS = {
  darkRed: '#B00020',
  softRed: '#DB3C3F',
  red: '#ED2537',
  midPink: '#FF5E69',
  lightPink: '#FBE7E7',
  lightPink2: '#FCEBEE',
  white: '#FFFFFF',
  offWhite: '#FAFAFA',
  offwhite2: '#FCFCFC',
  lightGray: '#E4E4E4',
  lightestGray: '#f2f2f2',
  black: '#380004',
  black_87: 'rgba(0, 0, 0, 0.87)',
  black_60: 'rgba(0, 0, 0, 0.6)',
  black_38: 'rgba(0, 0, 0, 0.38)',
  black_12: 'rgba(0, 0, 0, 0.12)',
  blue: '#376691',
  lightBlue_25: 'rgba(196, 204, 234, 0.25)',
  lightCeleste: 'rgba(42, 183, 202, 0.1)',
  lightBlue2: 'rgba(63, 81, 181, 0.08)',
  softYellow: '#FFED86',
  /** Custom orange color used in radmin. @warning Not approved by design team. */
  lightestOrange: '#FFEDE8',

  /** Outcomes4Me Branding */
  O4M_ORANGE: '#FF7958',
  O4M_DARKER_ORANGE: '#9E4B37',
  O4M_DARKEST_ORANGE: '#663023',
  O4M_LIGHT_ORANGE: '#F4BDA0',
  O4M_CREAM: '#EECCC1',
  O4M_LIGHT_CREAM: '#F5EBE7',
  O4M_DARK_BLUE: '#162937',
  O4M_BLUE: '#407996',
  O4M_LIGHT_BLUE: '#A9BED9',
  O4M_LIGHTEST_BLUE: '#EDF3F5',
  O4M_DARK_RED: '#B00020',
  O4M_LIGHTEST_GREY: '#E4E4E4',
  O4M_YELLOW: '#FFCB46',

  /** @deprecated Old Outcomes4Me Branding */
  O4MeDarkRed: '#B00020',
  /** @deprecated Old Outcomes4Me Branding */
  O4MeRed: '#ED2537',
  /** @deprecated Old Outcomes4Me Branding */
  O4MeOrange: '#EFA700',
  /** @deprecated Old Outcomes4Me Branding */
  O4MeBlue: '#3CDBD8',
  /** @deprecated Old Outcomes4Me Branding */
  O4MePink: '#FFBFC4',
  /** @deprecated Old Outcomes4Me Branding */
  O4MeLightGray: '#E4E4E4',
  /** @deprecated Old Outcomes4Me Branding */
  O4MeDarkGray: '#212121',
  /** @deprecated Old Outcomes4Me Branding */
  O4MeDarkBlue: '#2AB7CA',
  /** @deprecated Old Outcomes4Me Branding */
  disabledField: '#DCDCDC',
  /** @deprecated Old Outcomes4Me Branding */
  error: '#B00020',
};
